import React from 'react';
import Table from 'react-bootstrap/Table';

const CampaignResponsesTable = ({ responses }) => {
    responses = JSON.parse(responses);
    // Parse each response item and extract phone numbers and statuses
    const parsedResponses = responses.flatMap((items) => {
        const item= JSON.parse(items); // Parse JSON string
        return item.contacts.map((contact, index) => ({
            phoneNumber: contact.wa_id,
            status: item.messages[index]?.message_status || "N/A",
        }));
    });

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Phone Number</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {parsedResponses.length > 0 ? (
                    parsedResponses.map((item, index) => (
                        <tr key={index}>
                            <td>{item.phoneNumber}</td>
                            <td>{item.status}</td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="3" className="text-center">No responses available</td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}

export default CampaignResponsesTable;
