import React, { createContext, useContext, useState, useCallback } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);

    const showToast = useCallback((message, variant = 'success') => {
        const id = Date.now();
        setToasts(prevToasts => [...prevToasts, { id, message, variant }]);

        // Auto-remove the toast after a delay
        setTimeout(() => {
            setToasts(prevToasts => prevToasts.filter(toast => toast.id !== id));
        }, 3000);
    }, []);

    const contextValue = {
        showSuccess: (message) => showToast(message, 'success'),
        showError: (message) => showToast(message, 'danger')
    };

    return (
        <ToastContext.Provider value={contextValue}>
            {children}
            <ToastContainer position="top-end" className="p-3">
                {toasts.map((toast) => (
                    <Toast key={toast.id} bg={toast.variant} onClose={() => setToasts(toasts.filter(t => t.id !== toast.id))}>
                        <Toast.Body>{toast.message}</Toast.Body>
                    </Toast>
                ))}
            </ToastContainer>
        </ToastContext.Provider>
    );
};
