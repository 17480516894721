import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import BreadcrumbComponent from './BreadcrumbComponent';
import { useNavigate } from "react-router-dom";
import { useToast } from './ToastContext';

export default function AddContact() {
    const apiUrl = process.env.REACT_APP_API_URL;

    const { showSuccess, showError } = useToast();
    const handleSuccess = (message) => showSuccess(message);
    const handleError = (message) => showError(message);

    const breadcrumb_data = [
        {
            name: "Home",
            link: "/home",
            active: false
        },
        {
            name: "Contacts",
            link: "/contacts",
            active: false
        },
        {
            name: "Add Contact",
            link: "/add_contact",
            active: true
        }
    ];

    let navigate = useNavigate();
    const navigateToContacts = () => {
        let path = `/contacts`;
        navigate(path);
    }

    const [contactGroupName, setContactGroupName] = useState('');
    const [contactFile, setContactFile] = useState(null);
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');

    const handleContactGroupNameChange = (e) => setContactGroupName(e.target.value);
    const handleFileChange = (e) => setContactFile(e.target.files[0]);

    const validateForm = () => {
        const newErrors = {};
        if (!contactGroupName) newErrors.contactGroupName = 'Contact Group Name is required';
        else if (contactGroupName.length > 50) newErrors.contactGroupName = 'Must be 50 characters or less';

        if (!contactFile) newErrors.contactFile = 'File upload is required';
        else if (contactFile.size > 1 * 1024 * 1024) newErrors.contactFile = 'File size should be less than 1 MB';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        const formData = new FormData();
        formData.append('group_name', contactGroupName);
        formData.append('uploaded_file', contactFile);

        try {
            const response = await fetch(`${apiUrl}contact-groups/add`, {
                method: 'POST',
                body: formData
            });
            if (response.ok) {
                handleSuccess('Contacts added successfully!');
                setContactGroupName('');
                setContactFile(null);
                setErrors({});
            }  else {
                const errorData = await response.json();
                //setErrors({ submit: errorData.messages.error || 'Failed to add contacts' });
                handleError(errorData.messages.error ? errorData.messages.error : 'Failed to add contacts');
            }
        } catch (error) {
            // setErrors({ submit: 'Error submitting form' });
            handleError('Error submitting form');
        }
    };

    return (
        <>
            <Row>
                <Col md={3}>
                    <BreadcrumbComponent data={breadcrumb_data} />
                </Col>
                <Col md={1} className="ms-auto">
                    <Button variant="primary" onClick={navigateToContacts} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-circle" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                        </svg>
                        <span>Back</span>
                    </Button>
                </Col>
            </Row>
            <Container>
                <Row>
                    <Col md={6}  className="justify-content-between">
                        <Form onSubmit={handleSubmit}>
                            {successMessage && <Alert variant="success">{successMessage}</Alert>}
                            <Form.Group controlId="contactGroupName">
                                <Form.Label>Contact Group Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={contactGroupName}
                                    onChange={handleContactGroupNameChange}
                                    isInvalid={!!errors.contactGroupName}
                                    maxLength={50}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.contactGroupName}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="contactFile">
                                <Form.Label>Upload Contacts File</Form.Label>
                                <div className="d-flex align-items-center">
                                    <Form.Control
                                        type="file"
                                        onChange={handleFileChange}
                                        isInvalid={!!errors.contactFile}
                                    />
                                    <a href="./details.xlsx" download className="ms-2">
                                        Download sample template
                                    </a>
                                </div>
                                <Form.Control.Feedback type="invalid">
                                    {errors.contactFile}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Button variant="primary" className='mt-2' type="submit">
                                Submit
                            </Button>
                            {errors.submit && <Alert variant="danger" className="mt-3">{errors.submit}</Alert>}
                        </Form>
                    </Col>
                </Row>


            </Container>

        </>
    )
}