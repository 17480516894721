import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import BreadcrumbComponent from './BreadcrumbComponent';
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { useToast } from './ToastContext';

export default function AddCampaign() {
    const apiUrl = process.env.REACT_APP_API_URL;

    const { showSuccess, showError } = useToast();
    const handleSuccess = (message) => showSuccess(message);
    const handleError = (message) => showError(message);

    const breadcrumb_data = [
        {
            name: "Home",
            link: "/home",
            active: false
        },
        {
            name: "Campaigns",
            link: "/campaigns",
            active: false
        },
        {
            name: "Add Campaigns",
            link: "/add_campaigns",
            active: true
        }
    ];

    let navigate = useNavigate();
    const navigateToCampaigns = () => {
        let path = `/campaigns`;
        navigate(path);
    }

    const [campaignName, setCampaignName] = useState('');
    const [contactGroup, setContactGroup] = useState('');
    const [templateName, setTemplateName] = useState('');
    const [fields, setFields] = useState([{ type: '', value: '' }]);
    const [contactGroups, setContactGroups] = useState([]);
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        const fetchContactGroups = async () => {
            try {
                const response = await fetch(`${apiUrl}/contact-groups`);
                const data = await response.json();
                if (response.ok) {
                    setContactGroups(data); // Set fetched contact groups
                } else {
                    // setErrors((prevErrors) => ({ ...prevErrors, contactGroup: 'Failed to load contact groups' }));
                    handleError('Failed to load contact groups');
                }
            } catch (error) {
                // setErrors((prevErrors) => ({ ...prevErrors, contactGroup: 'Error fetching contact groups' }));
                handleError('Error fetching contact groups');
            }
        };
        fetchContactGroups();
    }, []);

    /*const handleFieldChange = (index, event) => {
        const newFields = fields.map((field, idx) =>
            index === idx ? { ...field, [event.target.name]: event.target.value } : field
        );
        setFields(newFields);
    };

    /*const handleAddField = () => setFields([...fields, { type: '', value: '' }]);

    const handleRemoveField = (index) => {
        const newFields = fields.filter((_, idx) => idx !== index);
        setFields(newFields);
    };*/

    const validateForm = () => {
        const newErrors = {};
        if (!campaignName) newErrors.campaignName = 'Campaign Name is required';
        else if (campaignName.length > 50) newErrors.campaignName = 'Must be 50 characters or less';

        if (!contactGroup) newErrors.contactGroup = 'Contact Group is required';
        if (!templateName) newErrors.templateName = 'Template Name is required';

        /*fields.forEach((field, index) => {
            if (!field.type) newErrors[`type-${index}`] = 'Type is required';
            if (!field.value) newErrors[`value-${index}`] = 'Value is required';
        });*/

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        const payload = {
            campaign_name: campaignName,
            contact_group_id : contactGroup,
            template_name: templateName,
            fields
        };

        try {
            const response = await fetch(`${apiUrl}campaigns/create`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                handleSuccess('Campaign added successfully!');
                setCampaignName('');
                setContactGroup('');
                setTemplateName('');
                // setFields([{ type: '', value: '' }]);
                setErrors({});
            } else {
                const errorData = await response.json();
                //setErrors({ submit: errorData.messages.error || 'Failed to add campaign' });
                if(errorData.messages.error){
                    handleError(errorData.messages.error);
                }else{
                    handleError('Failed to add campaign');
                }
            }
        } catch (error) {
            // setErrors({ submit: 'Error submitting form' });
            handleError('Error submitting form');
        }
    };

    return (
        <>
            <Row>
                <Col md={3}>
                    <BreadcrumbComponent data={breadcrumb_data} />
                </Col>
                <Col md={1} className="ms-auto">
                    <Button variant="primary" onClick={navigateToCampaigns} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-circle" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                        </svg>
                        <span>Back</span>
                    </Button>

                </Col>
            </Row>
            <Container className='mt-3 form-container'>
                <Row>
                    <Col md={6} className="justify-content-between">
                        <Form onSubmit={handleSubmit}>
                            {successMessage && <Alert variant="success">{successMessage}</Alert>}

                            <Form.Group controlId="campaignName">
                                <Form.Label>Campaign Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={campaignName}
                                    onChange={(e) => setCampaignName(e.target.value)}
                                    isInvalid={!!errors.campaignName}
                                    maxLength={50}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.campaignName}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="contactGroup">
                                <Form.Label>Select Contact Group</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={contactGroup}
                                    onChange={(e) => setContactGroup(e.target.value)}
                                    isInvalid={!!errors.contactGroup}
                                >
                                    <option value="">Choose...</option>
                                    {contactGroups.map((group) => (
                                        <option key={group.id} value={group.id}>{group.group_name}</option>
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">{errors.contactGroup}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="templateName">
                                <Form.Label>Enter Template Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={templateName}
                                    onChange={(e) => setTemplateName(e.target.value)}
                                    isInvalid={!!errors.templateName}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.templateName}
                                </Form.Control.Feedback>
                            </Form.Group>

                            {/* {fields.map((field, index) => (
                                <div key={index} className="d-flex align-items-center mb-2">
                                    <Form.Group controlId={`type-${index}`} className="me-3">
                                        <Form.Label>Type</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="type"
                                            value={field.type}
                                            onChange={(e) => handleFieldChange(index, e)}
                                            isInvalid={!!errors[`type-${index}`]}
                                        >
                                            <option value="">Choose...</option>
                                            <option value="Link">Link</option>
                                            <option value="Text">Text</option>
                                            <option value="Date">Date</option>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors[`type-${index}`]}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group controlId={`value-${index}`} className="me-3">
                                        <Form.Label>Value</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="value"
                                            value={field.value}
                                            onChange={(e) => handleFieldChange(index, e)}
                                            isInvalid={!!errors[`value-${index}`]}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors[`value-${index}`]}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Button variant="danger" className='mt-4' onClick={() => handleRemoveField(index)}>
                                        Remove
                                    </Button>
                                </div>
                            ))}

                            <Button variant="secondary" onClick={handleAddField}>
                                Add More
                            </Button> */}

                            <div className="d-flex justify-content-end mt-3">
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </div>

                            {errors.submit && <Alert variant="danger" className="mt-3">{errors.submit}</Alert>}
                        </Form>
                    </Col>
                </Row>
            </Container>

        </>
    )
}