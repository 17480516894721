import './App.css';
import { Routes, Route } from 'react-router-dom';
// import Footer from './Footer.js'
import LayoutComponent from './LayoutComponent.js'
import Home from './Home.js'
import Campaigns from './Campaigns.js'
import Contacts from './Contacts.js'
import AddCampaign from './AddCampaign.js'
import AddContact from './AddContact.js'
import ViewCampaign from './ViewCampaign.js'

function App() {
  
  return (

    <Routes>
      <Route path="/" element={<LayoutComponent />}>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/campaigns" element={<Campaigns />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/add_campaign" element={<AddCampaign />} />
        <Route path="/add_contact" element={<AddContact />} />
        <Route path='/view_campaign/:id' element={<ViewCampaign/>} />
      </Route>
    </Routes>

  );
}

export default App;
