import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { Container, Row, Col } from 'react-bootstrap';
import BreadcrumbComponent from './BreadcrumbComponent';
import Accordion from 'react-bootstrap/Accordion'
import { formatDateTime } from './Utils';
import CampaignResponsesTable from './CampaignResponsesTable';

const ViewCampaign = () => {

    const apiUrl = process.env.REACT_APP_API_URL;
    const breadcrumb_data = [
        {
            name: "Home",
            link: "/home",
            active: false
        },
        {
            name: "Campaigns",
            link: "/campaigns",
            active: false
        },
        {
            name: "View Campaign",
            link: "/view_campaign",
            active: true
        }
    ];

    const params = useParams();
    const [campaignData, setCampaignData] = useState([]);
    const hasFetched = useRef(false);

    let navigate = useNavigate();
    const navigateToCampaigns = () => {
        let path = `/campaigns`;
        navigate(path);
    }

    useEffect(() => {
        if (hasFetched.current) return;
        fetch(`${apiUrl}campaigns/getCampaignDetails/${params.id}`).then(
            res => res.json()
        ).then(data => setCampaignData(data?.data))
        hasFetched.current = true;
    }, [params.id])



    return (
        <>
            <Row>
                <Col md={3}>
                    <BreadcrumbComponent data={breadcrumb_data} />
                </Col>
                <Col md={1} className="ms-auto">
                    <Button variant="primary" onClick={navigateToCampaigns} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-circle" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                        </svg>
                        <span>Back</span>

                    </Button>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col md={4}>
                    <b>Campaign Name :</b> {campaignData.campaign_name}
                </Col>
                <Col md={4}>
                    <b>Template Name :</b> {campaignData.template_name}
                </Col>
                <Col md={4}>
                    <b>Contact Group Name :</b> {campaignData.contact_group_name}
                </Col>
            </Row>
            <Container className='mt-3 form-container'>
                <Accordion defaultActiveKey="0">
                    {
                        campaignData && campaignData?.responses && campaignData?.responses.length > 0 &&
                        campaignData?.responses.map((response, index) =>
                            <Accordion.Item eventKey={index}  key={index}>
                                <Accordion.Header>Campaign on {formatDateTime(response.created_at)}</Accordion.Header>
                                <Accordion.Body>
                                    <CampaignResponsesTable responses={response.response} />
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    }
                    
                </Accordion>
            </Container>
        </>
    );

}

export default ViewCampaign;