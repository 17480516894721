import { Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import BreadcrumbComponent from './BreadcrumbComponent';
import { useNavigate } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import { useEffect, useRef, useState } from 'react';
import { useToast } from './ToastContext';

let formatDate = require('./Utils.js').formatDate;

export default function Contacts() {

    const apiUrl = process.env.REACT_APP_API_URL;

    const { showSuccess, showError } = useToast();
    const handleSuccess = (message) => showSuccess(message);
    const handleError = (message) => showError(message);

    const breadcrumb_data = [
        {
            name: "Home",
            link: "/home",
            active: false
        },
        {
            name: "Contacts",
            link: "",
            active: true
        }
    ];

    let navigate = useNavigate();
    const navigateToAddContacts = () => {
        let path = `/add_contact`;
        navigate(path);
    }

    const hasFetched = useRef(false);
    const [contacts, setContacts] = useState([]);
    const [refresh_status, setRefreshStatus] = useState(true);

    useEffect(() => {
        if (hasFetched.current) return;
        fetch(`${apiUrl}contact-groups`).then(
            res => res.json()
        ).then(data => setContacts(data))
        hasFetched.current = true;
    }, [refresh_status])

    
    const filePath = process.env.REACT_APP_FILE_PATH;

    const getContactGroupFile = (fileName) => {
        return fileName == null ? null : filePath + fileName;
    }

    const downloadFile = async (event, contact) => {
        event.preventDefault();
        try {
            const response = await fetch(`${apiUrl}/files/download/${contact.uploaded_file}`, {
                method: 'GET',
            });
            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', contact.group_name + '.' + contact.uploaded_file.split('.').pop());
                document.body.appendChild(link);
                link.click();
                link.remove();
            } else {
                console.error('Failed to download file');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleDelete = async (event, groupId) => {
        event.preventDefault();
        try {
            const response = await fetch(`${apiUrl}contact-groups/remove/${groupId}`, {
                method: 'DELETE'
            });
            const data = await response.json();
            if (response.ok) {
                alert(data.message);  // Display the message to the user
                // Refresh or update contact groups list here
                setRefreshStatus(!refresh_status);
                hasFetched.current = false;
            } else {
                alert('Failed to delete the contact group');
            }
        } catch (error) {
            console.error('Error deleting contact group:', error);
        }
    };
    
    

    return (
        <>
            <Row>
                <Col md={3}>
                    <BreadcrumbComponent data={breadcrumb_data} />
                </Col>
                <Col md={1} className="ms-auto">
                    <Button variant="primary" onClick={navigateToAddContacts} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-add" viewBox="0 0 16 16">
                            <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4" />
                            <path d="M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z" />
                        </svg>
                        <span>Add</span>
                    </Button>
                </Col>
            </Row>
            <Container className='mt-3'>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Contact Group Name</th>
                            <th>Created Date</th>
                            <th>Download File</th>
                            <th>Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            contacts.map((contact, index) =>
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{contact.group_name}</td>
                                    <td>{formatDate(contact.created_at)}</td>
                                    <td><a href="#" onClick={(event) => downloadFile(event, contact)} target='_blank' download ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-arrow-down" viewBox="0 0 16 16">
  <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293z"/>
  <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
</svg></a></td>
                                    <td><a href="#" onClick={(event) => handleDelete(event, contact.id)}  ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
  <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
</svg></a></td>
                                </tr>
                            )
                        }
                        
                    </tbody>
                </Table>

            </Container>

        </>
    )
}