import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { Container, Row, Col } from 'react-bootstrap';
import BreadcrumbComponent from './BreadcrumbComponent';
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from 'react';
import { useToast } from './ToastContext';

let formatDate = require('./Utils.js').formatDate;

export default function Campaigns() {
    const apiUrl = process.env.REACT_APP_API_URL;

    const { showSuccess, showError } = useToast();
    const handleSuccess = (message) => showSuccess(message);
    const handleError = (message) => showError(message);

    const breadcrumb_data = [
        {
            name: "Home",
            link: "/home",
            active: false
        },
        {
            name: "Campaigns",
            link: "",
            active: true
        }
    ];

    let navigate = useNavigate();
    const navigateToAddCampaign = () => {
        let path = `/add_campaign`;
        navigate(path);
    }

    const hasFetched = useRef(false);
    const [campaigns, setCampaigns] = useState([]);
    const [refresh_status, setRefreshStatus] = useState(true);

    useEffect(() => {
        if (hasFetched.current) return;
        fetch(`${apiUrl}campaigns`).then(
            res => res.json()
        ).then(data => setCampaigns(data))
        hasFetched.current = true;
    }, [refresh_status])

    const viewCampaign = (event, campaignId) => {
        event.preventDefault();
        let path = `/view_campaign/${campaignId}`;
        navigate(path);
    }

    const runCampaign = async (event, campaignId) => {
        event.preventDefault();
        try {
            const response = await fetch(`${apiUrl}campaigns/send`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ campaign_id: campaignId })
            });

            if (response.ok) {
                //setSuccessMessage('Campaign added successfully!');
                handleSuccess('Campaign sent successfully!');
            } else {
                const errorData = await response.json();
                //setErrors({ submit: errorData.messages.error || 'Failed to add campaign' });
            }
        } catch (error) {
            //setErrors({ submit: 'Error submitting form' });
        }
    }



    return (
        <>

            <Row>
                <Col md={2}>
                    <BreadcrumbComponent data={breadcrumb_data} />
                </Col>
                <Col md={1} className="ms-auto">
                    <Button variant="primary" onClick={navigateToAddCampaign} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <span>Add</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-megaphone" viewBox="0 0 16 16">
                            <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 75 75 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0m-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233q.27.015.537.036c2.568.189 5.093.744 7.463 1.993zm-9 6.215v-4.13a95 95 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A61 61 0 0 1 4 10.065m-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68 68 0 0 0-1.722-.082z" />
                        </svg>
                    </Button>
                </Col>
            </Row>


            <div className='row mt-3'>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Campaign Name</th>
                            <th>Template Name</th>
                            <th>Contact Group Name</th>
                            <th>Created Date</th>
                            <th>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            campaigns.map((campaign, index) =>
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{campaign.campaign_name}</td>
                                    <td>{campaign.template_name}</td>
                                    <td>{campaign.group_name}</td>
                                    <td>{formatDate(campaign.created_at)}</td>
                                    <td><a href='#' onClick={((event) => viewCampaign(event, campaign.id))}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                    </svg></a>
                                        <a href='#' className='ml-10' onClick={((event) => runCampaign(event, campaign.id))}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
                                            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
                                        </svg></a>
                                    </td>
                                </tr>
                            )
                        }


                    </tbody>
                </Table>
            </div>

        </>

    );
}