import Breadcrumb from 'react-bootstrap/Breadcrumb';

export default function BreadcrumbComponent(props) {
    return (
        <Breadcrumb>
            {props.data.map((item, index) => (
                item.active ? (
                    <Breadcrumb.Item key={index} active>
                        {item.name}
                    </Breadcrumb.Item>
                ) : (
                    <Breadcrumb.Item key={index} href={item.link}>
                        {item.name}
                    </Breadcrumb.Item>
                )
            ))}
        </Breadcrumb>
    )
}